import React from 'react'
import { Grid } from 'semantic-ui-react'
import EmailIcon  from '../../img/EmailIcon.svg'
import TelephoneIcon  from '../../img/TelephoneIcon.svg'
import { contactsData } from './data.js'


export class Contacts extends React.Component {
  render(){
    const columnsNumber = contactsData.length
    return(
      <Grid container stackable verticalAlign='middle' >
      <Grid.Row verticalAlign='middle' columns={columnsNumber} style={{marginTop:'40px', marginBottom:'40px'}}>
      {contactsData.map( card => <Grid.Column key={card.value}>
           <ContactCard  title={card.title}  value={card.value} src={ card.type === 'email' ? EmailIcon : TelephoneIcon} />
       </Grid.Column>
       ) }
      </Grid.Row>
    </Grid>
    )
  }
}

  // export const Contacts = ()=> {
  //     const columnsNumber = contactsData.length
  //   return (
  //     <Grid container stackable verticalAlign='middle' >
  //       <Grid.Row verticalAlign='middle' columns={columnsNumber} style={{marginTop:'40px', marginBottom:'40px'}}>
  //       {contactsData.map( card => <Grid.Column key={card.value}>
  //            <ContactCard  title={card.title}  value={card.value} src={ card.type === 'email' ? EmailIcon : TelephoneIcon} />
  //        </Grid.Column>
  //        ) }
  //       </Grid.Row>
  //     </Grid>
  //   )
  // }
  
  const ContactCard = props => {
    return (
        <Grid style={{ justifyContent:'center', margin:'15px 10px 15px 10px', borderRadius:'12px', backgroundColor : '#f6f6f6', color:'#006BAD', padding:'10px 20px 10px 20px'}}>
           <div style={{borderRadius:'50%', background:'#FFFFFF', marginTop:'-45px'}}> 
           <img style={{ width:'50px', height: '50px'}} src={props.src} alt={props.src} />
           </div>
           <Grid.Row>
               <Grid.Column>
            <Grid.Row columns={2}>
               <Grid.Column> {props.title}: </Grid.Column>
               <div style={{height:'5px'}}></div>
               <Grid.Column >{props.value}</Grid.Column>
                </Grid.Row> 
          </Grid.Column>
           </Grid.Row>
        </Grid>
    )
  }
