import React from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { imagesNames } from './galeryData.js'

const PREFIX_URL = 'https://storage.googleapis.com/emkpublic/kyashif/products/';


export class ProductsGallery extends React.Component {

  constructor() {
    super();
    this.state = {
      showIndex: false,
      slideOnThumbnailHover: false,
      showBullets: false,
      infinite: true,
      showThumbnails: false,
      showFullscreenButton: false,
      showGalleryFullscreenButton: false,
      showPlayButton: false,
      showGalleryPlayButton: false,
      showNav: true,
      slideDuration: 450,
      slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},
    };
  this.images = this.createImagesArr().concat(this._getStaticImages());
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
        this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }
  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }
  _onPause(index) {
    console.debug('paused on index', index);
  }
  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }
  _onPlay(index) {
    console.debug('playing from index', index);
  }
  _handleInputChange(state, event) {
    this.setState({[state]: event.target.value});
  }
  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }
  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }
  createImagesArr = () => {
    var imageURLS = []
    imagesNames.forEach(name => {
          const imageObj = {
            original: `${PREFIX_URL}${name}`,
            originalClass: 'featured-slide',
            thumbnailClass: 'featured-thumb',
            originalAlt: 'hydraulic cylinder',
            thumbnailAlt: 'hydraulic cylinder'
        };
        imageURLS.push(imageObj)
    });
    return imageURLS
  };
  _getStaticImages() {
    let images = [];
    for (let i = 2; i < 2; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail:`${PREFIX_URL}${i}t.jpg`
      });
    }

    return images;
  }
  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }
  _toggleShowVideo(url) {
    this.setState.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({showVideo: this.state.showVideo});

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }
  _renderVideo(item) {
    return (
      <div className='image-gallery-image'>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <div
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </div>
                <iframe
                  title='gallery'
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <div onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img src={item.original} alt='kyashif hydraulic'/>
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </div>
        }
      </div>
    );
  }
  render() {
    return (
        <section className='appGallery' style={{maxHeight:"350px"}}>
          <div style={{maxHeight: "300px",  }}>
            <ImageGallery
                ref={i => this._imageGallery = i}
                items={this.images}
                lazyLoad={false}
                onClick={this._onImageClick.bind(this)}
                onImageLoad={this._onImageLoad}
                onSlide={this._onSlide.bind(this)}
                onPause={this._onPause.bind(this)}
                onScreenChange={this._onScreenChange.bind(this)}
                onPlay={this._onPlay.bind(this)}
                infinite={this.state.infinite}
                showBullets={this.state.showBullets}
                showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                showThumbnails={this.state.showThumbnails}
                showIndex={this.state.showIndex}
                showNav={this.state.showNav}
                thumbnailPosition={this.state.thumbnailPosition}
                slideDuration={parseInt(this.state.slideDuration, 10)}
                slideInterval={parseInt(this.state.slideInterval, 10)}
                slideOnThumbnailHover={this.state.slideOnThumbnailHover}
                additionalClass="app-image-gallery"
            />
          </div>
        </section>
    );
  }
}