  // const imagePath = '/img/capabilities/';
//   const imagePath = 'https://storage.googleapis.com/emkpublic/kyashif/Images/';
  const imagePath = 'https://storage.googleapis.com/emkpublic/kyashif/Images/Capabilities/';

  const thumbnailWidth= 40
  const thumbnailHeight = 35
export const photosArray = [
    {
        src: `${imagePath}${'1Mechanical1.jpg'}`,
        thumbnail: `${imagePath}${'1Mechanical1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip2.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip2Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip4.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip4Thumb.jpg'}`,
        thumbnailWidth: 60,
        thumbnailHeight: 30,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip5.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip5Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },

    {
        src: `${imagePath}${'3Tacchi1.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi1Thumb.jpg'}`,
        thumbnailWidth: 60,
        thumbnailHeight: 30,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'3Tacchi3.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi3Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: 40,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS1.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS3.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'5Thompson1.png'}`,
        thumbnail: `${imagePath}${'5Thompson1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly1.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly3.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly4.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly4Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly6.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly6Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly7.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly7Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop1.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop4.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop4Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop12.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop12Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality11.jpg'}`,
        thumbnail: `${imagePath}${'8Quality11thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality3.jpg'}`,
        thumbnail: `${imagePath}${'8Quality3Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: 45,
        alt: 'Kyashif production'
    },

]

export const photosArrayMobile = [
    {
        src: `${imagePath}${'1Mechanical1.jpg'}`,
        thumbnail: `${imagePath}${'1Mechanical1Thumb.jpg'}`,
        thumbnailWidth: 140,
        thumbnailHeight: 120,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip2.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip2Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip4.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip4Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'2MachineEquip5.jpg'}`,
        thumbnail: `${imagePath}${'2MachineEquip5Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },

    {
        src: `${imagePath}${'3Tacchi1.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'3Tacchi3.jpg'}`,
        thumbnail: `${imagePath}${'3Tacchi3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS1.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS1Thumb.jpg'}`,
        thumbnailWidth: 33,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'4CLOOS3.jpg'}`,
        thumbnail: `${imagePath}${'4CLOOS3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'5Thompson1.png'}`,
        thumbnail: `${imagePath}${'5Thompson1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly1.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly3.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly3Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly4.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly4Thumb.jpg'}`,
        thumbnailWidth: 50,
        thumbnailHeight: 35,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly6.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly6Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'6Assembly7.jpg'}`,
        thumbnail: `${imagePath}${'6Assembly7Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop1.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop1Thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop4.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop4Thumb.jpg'}`,
        thumbnailWidth: 50,
        thumbnailHeight: 35,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'7PaintingShop12.jpg'}`,
        thumbnail: `${imagePath}${'7PaintingShop12Thumb.jpg'}`,
        thumbnailWidth: 50,
        thumbnailHeight: 35,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality11.jpg'}`,
        thumbnail: `${imagePath}${'8Quality11thumb.jpg'}`,
        thumbnailWidth: thumbnailWidth,
        thumbnailHeight: thumbnailHeight,
        alt: 'Kyashif production'
    },
    {
        src: `${imagePath}${'8Quality3.jpg'}`,
        thumbnail: `${imagePath}${'8Quality3Thumb.jpg'}`,
        thumbnailWidth: 35,
        thumbnailHeight: 40,
        alt: 'Kyashif production'
    },
]


// export const imagesNames = [
//     {
//         src:'1Mechanical1.jpg',
//         thumbnail: '1Mechanical1Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip2.jpg',
//         thumbnail: '2MachineEquip2Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip4.jpg',
//         thumbnail: '2MachineEquip4Thumb.jpg'
//     },
//     {
//         src:'2MachineEquip5.jpg',
//         thumbnail: '2MachineEquip5Thumb.jpg'
//     },
//     {
//         src:'3Tacchi1.jpg',
//         thumbnail: '3Tacchi1Thumb.jpg'
//     },
//     {
//         src:'3Tacchi3.jpg',
//         thumbnail: '3Tacchi3Thumb.jpg'
//     },
//     {
//         src:'4CLOOS2.jpg',
//         thumbnail: '4CLOOS2Thumb.jpg'
//     },
//     {
//         src:'4CLOOS3.jpg',
//         thumbnail: '4CLOOS3Thumb.jpg'
//     },
//     {
//         src:'5Thompson1.jpg',
//         thumbnail: '5Thompson1Thumb.jpg'
//     },
//     {
//         src:'6Assembly1.jpg',
//         thumbnail: '6Assembly1Thumb.jpg'
//     },
//     {
//         src:'6Assembly3.jpg',
//         thumbnail: '6Assembly3Thumb.jpg'
//     },
//     {
//         src:'6Assembly4.jpg',
//         thumbnail: '6Assembly4Thumb.jpg'
//     },
//     {
//         src:'6Assembly6Thumb.jpg',
//         thumbnail: '6Assembly6Thumb.jpg'
//     },
//     {
//         src:'6Assembly7.jpg',
//         thumbnail: '6Assembly7Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop1.jpg',
//         thumbnail: '7PaintingShop1Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop4.jpg',
//         thumbnail: '7PaintingShop4Thumb.jpg'
//     },
//     {
//         src:'7PaintingShop12.jpg',
//         thumbnail: '7PaintingShop12Thumb.jpg'
//     },
//     {
//         src:'8Quality11.jpg',
//         thumbnail: '8Quality11thumb.jpg'
//     },
// ]



// export const imagesNames = [
//     '1Collage11.svg',
//     '1Mechanical1th.JPG', 
//     '2MachineEquip1.PNG', 
//     '2MachineEquip3.JPG', 
//     '2MachineEquip4th.jpg', 
//     '2MachineEquip5th.jpg',
//     '2MachineEquip6.jpg', 
//     '2MachineEquip7.jpg', 
//     '2MachineEquip8.jpg', 
//     '3Tacchi1.JPG', 
//     '3Tacchi2.jpg', 
//     '3Tacchi3th.jpg', 
//     '3Tacchi4.jpg', 
//     '4CLOOS1.png', 
//     '4CLOOS2th.jpg', 
//     '4CLOOS3th.jpg', 
//     '4CLOOS4.jpg', 
//     '5Thompson1th.png', 
//     '5Thompson2th.PNG', 
//     '5Thompson3th.JPG', 
//     '5Thompson4.jpg', 
//     '6Assembly1th.jpg', 
//     '6Assembly2.JPG', 
//     '6Assembly3.jpg', 
//     '6Assembly4th.JPG',
//     '6Assembly5.jpg',  
//     '6Assembly6.png',  
//     '6Assembly7th.JPG',  
//     '6Assembly8.JPG',  
//     '6Assembly9.jpg',  
//     '7PaintingShop1th.jpg', 
//     '7PaintingShop2.jpg',  
//     '7PaintingShop4.JPG', 
//     '8Quality1th.jpg', 
//     '8Quality2th.png',
//     '8Quality3.jpg',
//     '8Quality4.jpg',
//     '8Quality5.jpg',
//     '8Quality6.jpg',
//     '8Quality7.png',
//     '8Quality8.JPG',
//     '8Quality9.jpg',
//     '8Quality11th.jpg',
//     '8Quality12.JPG',
    
// ]

// exluded - '8Quality12.JPG', '8Quality13.PNG', '8Quality2.png',  '8Quality3.jpg',  '8Quality4.jpg',  '8Quality5.jpg',  '8Quality6.jpg',  '8Quality7.png', '8Quality8.JPG', '8Quality9.jpg',