export const imagesNames = [
    'Cylinder1.jpg', 
    // 'Cylinder2.jpg',
    'Cylinder3.jpg', 
    'Cylinder4.jpg', 
    'Cylinder5.jpg',
    'Cylinder6.jpg', 
    // 'Cylinder7.jpg',
    'Cylinder8.jpg', 
    'Cylinder9.jpg', 
    'Cylinder10.jpg',
    'Cylinder11.jpg',
    'Cylinder12.jpg',
    'Cylinder13.jpg',
    'Cylinder14.jpg',
] 