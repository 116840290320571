export const contactsData = [
    {
        type:'email',
        title:'general info',
        value:'info@kyashif.com',
     },
     {
        type:'phone',
        title:'sales inquiry ',
        value:'+359886892895'
     },
     {
        type:'email',
        title:'sales inquiry ',
        value:'mnikolova@kyashif.com'
     },
    ]