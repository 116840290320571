import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import {Milestones} from './Milestones.jsx'
import {Statistics} from '../statistics/Statistics.jsx'
import {
    Divider,
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react'

export class About extends React.Component {
    render() {
        return (
            <Segment basic style={{padding: '8.5em 0em'}} vertical>
                <Divider hidden/>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <ScrollAnimation
                                animateIn="fadeInRight"
                                animateOnce={true}>
                                <Header as='h3' style={{fontSize: '3.5em', fontFamily: 'Montserrat'}}>About us</Header>
                                <Description/>
                            </ScrollAnimation>
                        </Grid.Column>
                        <Grid.Column floated='right' width={8}>
                            <Video/>
                        </Grid.Column>
                    </Grid.Row>
                    <Milestones/>
                    <Grid.Row>
                        <Statistics/>
                    </Grid.Row>
                </Grid>
                <Divider hidden/>
            </Segment>
        )
    }
}

// export const About = () => {
//     return(
//         <Segment basic style={{ padding: '8.5em 0em' }} vertical>
//             <Divider hidden />
//             <Grid container stackable verticalAlign='middle'>
//                 <Grid.Row>
//                     <Grid.Column width={7}>
//                     <ScrollAnimation
//                         animateIn="fadeInRight"
//                         animateOnce={true}>
//                         <Header as='h3' style={{ fontSize: '3.5em',   fontFamily: 'Montserrat' }}>About us</Header>
//                          <Description/>
//                     </ScrollAnimation>
//                     </Grid.Column>
//                     <Grid.Column floated='right' width={8}>
//                         <Video/>
//                     </Grid.Column>
//                 </Grid.Row>
//                 <Milestones/>
//                 <Grid.Row >
//                 </Grid.Row>
//             </Grid>
//             <Divider hidden />
//         </Segment>
//     )
// }

const Description = () => {
    return (
        <p style={{fontSize: '1.5em'}}>
            KYASHIF is a fast growing and innovative company, specialized in design and manufacture of hydraulic
            cylinders.
            We continuously improve and invest in new equipment and technologies. <br/><br/>
            We are confident that we can become your most reliable supplier of hydraulic cylinders! <br/><br/>
        </p>
    )
}

const Video = () => {
    return (
        <ScrollAnimation
            animateIn="fadeInLeft"
            animateOnce={true}>
            {/* <a href="https://storage.googleapis.com/emkpublic/kyashif/KYASHIF_2020.mp4" target="_blank" rel="noopener noreferrer"> */}
            <a href="https://storage.googleapis.com/emkpublic/kyashif/KYASHIF_2024_45_years.mp4" target="_blank"
               rel="noopener noreferrer">
                <div className="videoWrapper">
                    <video
                        id='ksfVideo'
                        width="100%"
                        autoPlay={true}
                        muted
                        loop
                        // controls
                        controlsList="nodownload noremoteplayback"
                    >
                        {/* <source src="https://storage.googleapis.com/emkpublic/kyashif/ksf-short-video.mp4" type="video/mp4"/> */}
                        <source src="https://storage.googleapis.com/emkpublic/kyashif/KYASHIF_2024_45_years.mp4"
                                type="video/mp4"/>

                    </video>
                </div>
            </a>
        </ScrollAnimation>
    )
}