import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {unregister} from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
      families: ['Montserrat:300', 'sans-serif']
    }
  });

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
