import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import Certificate1 from '../../img/certificates/Certificate1.svg'
import Certificate2 from '../../img/certificates/Certificate2.svg'
import Certificate3 from '../../img/certificates/Certificate3.svg'
import Certificate4 from '../../img/certificates/Certificate4.svg'

  export const Certificates = () => {
      return ( 
      <>
        < CertificatesComputerTablet className ="ui computer only grid" />
        < CertificatesComputerTablet className ="ui tablet only grid" />
        < CertificatesMobile className ="ui mobile only grid" />
      </>
      )
  }

  const CertificatesComputerTablet = props => {
    return (
      <Grid.Row centered className={props.className} columns={4}>
         < CertificateComponent src={Certificate1} />
         < CertificateComponent src={Certificate2} />
         < CertificateComponent src={Certificate3} />
         < CertificateComponent src={Certificate4} />
      </Grid.Row>
    )
  }

  const CertificatesMobile = props => {
    const className = props.className
    return (
    <>
      <Grid.Row className={className} columns={2}>
        < CertificateComponent src={Certificate1} />
        < CertificateComponent src={Certificate2} />
      </Grid.Row>
      <Grid.Row className={className} columns={2}>
      < CertificateComponent src={Certificate3} />
        < CertificateComponent src={Certificate4} />
      </Grid.Row>
    </>
     )
  }

  const CertificateComponent = props => {
    const src = props.src
    return (
      <Grid.Column>
        <Image centered id='' 
          alt={`${src}`}
          src={src}
          style={{width:'10.5em', height:'10.5em'}}
        />
      </Grid.Column >
     )
    }