import React from 'react';
import Gallery from 'react-grid-gallery';
import { photosArray,
     photosArrayMobile 
    } from './galeryData.js'
import { Grid } from 'semantic-ui-react'

    export const ImageGallery = () => {
    // const imagesArray = createImagesArr();
    return (
        <>
        <Grid.Column className={"ui computer only grid"} style={{display:'flex', flexDirection:'column', flexGrow:1, justifyContent:'center', alignItems:'center', alignContent:'center', }}>
        <Gallery images={photosArray} rowHeight={95} backdropClosesModal={true}  margin={0.5} preloadNextImage={true}/>
        </Grid.Column>
        <Grid.Column className={"ui tablet only grid"} style={{display:'flex', flexDirection:'column', flexGrow:1, justifyContent:'center', alignItems:'center', alignContent:'center',  }}>
        <Gallery images={photosArray}  rowHeight={62} backdropClosesModal={true}   margin={0.5} preloadNextImage={true}/>
        </Grid.Column>
        <Grid.Column className={"ui mobile only grid"} style={{display:'flex', flexDirection:'column', flexGrow:1, justifyContent:'center' }}>
        <Gallery images={photosArrayMobile} rowHeight={53} backdropClosesModal={true}  margin={0.5} preloadNextImage={true}/>
        </Grid.Column>
        </>
    );
}

  

//     const imageObject = (image) => {
//     var imageObj
//          imageObj = {
//             src: `${imagePath}${image.src}`,
//             thumbnail: `${imagePath}${image.thumbnail}`,
//             thumbnailWidth: thumbnailWidth,
//             thumbnailHeight: thumbnailHeight,
//             alt: 'Kyashif production'
//         };
//     return imageObj
// }
//     const createImagesArr = () => {
//         var imagesArray = []
//         imagesNames.forEach(image => {
//            const imageObj = imageObject(image)
//            console.log('imageObj',imageObj);
//         imagesArray.push(imageObj)
//       });
//       return imagesArray
//     };

  