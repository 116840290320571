import React from 'react'
import MilestonesHorizontal from '../../img/MilestonesHorizontal.svg'
import MilestonesVertical from '../../img/MilestonesVertical.svg'
import {Grid, Image} from 'semantic-ui-react'

export const Milestones = () => {
    return (
        <>
            <MilestoneComponent params={["ui computer only grid", MilestonesHorizontal, '16000px', '300px']}/>
            <MilestoneComponent params={["ui mobile only grid", MilestonesVertical, '700px', '700px']}/>
            <MilestoneComponent params={["ui tablet only grid", MilestonesVertical, '1200px', '1000px']}/>
        </>
    )
}

const MilestoneComponent = props => {
    const [className, src, width, height] = props.params
    return (
        <Grid.Row className={className} style={{marginTop: '20px'}}>
            <Image id={`${src}`}
                   alt={`${src}`}
                   src={src}
                   style={{
                       width: width,
                       height: height,
                   }}
            />
        </Grid.Row>
    )
}