import React from 'react'
import logo from '../../img/KSFLogo45Years.png'
import { StickyNavigation } from './Navigation'
import ScrollAnimation from 'react-animate-on-scroll'
import { Grid, Segment, Image, } from 'semantic-ui-react'

export const HomepageHeader = ({ mobile, desktop, tablet }) => (
     <Segment style={{padding:'0em 0em', height: mobile ? 'auto' : '100vh'}} vertical>
        <Grid>
          {/* <Grid.Row id='home-header' >
          </Grid.Row> */}
          <Grid.Row>
            <Grid.Column>
                <ScrollAnimation
                    animateIn="fadeInDown"
                    animateOut="fadeOutDown"
                    animateOnce={false}>
                    <Image
                      alt='Kyashif Ltd. Logo'
                      src={logo}
                      verticalAlign='middle'
                      style={{
                        marginTop: mobile ? '15em' : '18em',
                        // marginTop: mobile ? '8em' : '11em',
                        padding: '20px 45px'
                      }}
                    />
                 </ScrollAnimation>
            </Grid.Column>
          </Grid.Row>
        </Grid>
         <StickyNavigation/>
     </Segment>
)