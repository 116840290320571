import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { ProductsGallery } from './Gallery.jsx'
import { Divider, Grid, Header, List, Segment } from 'semantic-ui-react'

export class Products extends React.Component {
    render(){
        return(
            <Segment basic style={{ padding: '8em 0em' }} vertical>
            <Divider hidden />
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row style={{padding:'2em 0'}}>
                    <Grid.Column width={7} >
                        <ScrollAnimation 
                            animateIn="fadeInRight"
                            animateOnce={true}>
                            <Header as='h3' style={{ fontSize: '3.5em', fontFamily: 'Montserrat' }}>Hydraulic cylinders</Header>
                            <ProductsList/>
                        </ScrollAnimation>
                    </Grid.Column>
                    <Grid.Column floated='right' width={8}>
                        <ScrollAnimation 
                            animateIn="fadeInLeft"
                            animateOnce={true}>
                            <ProductsGallery />
                        </ScrollAnimation>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden />
        </Segment>
        )
    }
}

//   export const Products = () => {
//         return (
//             <Segment basic style={{ padding: '8em 0em' }} vertical>
//             <Divider hidden />
//             <Grid container stackable verticalAlign='middle'>
//                 <Grid.Row style={{padding:'2em 0'}}>
//                     <Grid.Column width={7} >
//                         <ScrollAnimation 
//                             animateIn="fadeInRight"
//                             animateOnce={true}>
//                             <Header as='h3' style={{ fontSize: '3.5em', fontFamily: 'Montserrat' }}>Hydraulic cylinders</Header>
//                             <ProductsList/>
//                         </ScrollAnimation>
//                     </Grid.Column>
//                     <Grid.Column floated='right' width={8}>
//                         <ScrollAnimation 
//                             animateIn="fadeInLeft"
//                             animateOnce={true}>
//                             <ProductsGallery />
//                         </ScrollAnimation>
//                     </Grid.Column>
//                 </Grid.Row>
//             </Grid>
//             <Divider hidden />
//         </Segment>
//         )
// }

 const ProductsList = () => {
     return (
        <List style={{ fontSize: '1.5em' }}>
            <List.Item>- Standard and custom design cylinders</List.Item> <br/>
            <List.Item>- Internal diameter from 40 to 320 mm; retracted length up to 6000 mm </List.Item> <br/>
            <List.Item>- Various types (including with sensors)</List.Item> <br/>
            <List.Item>- Various applications</List.Item>
        </List>
     )
 } 