import React from 'react'
import {Grid, Image} from 'semantic-ui-react'
import LinkedInIcon from '../../img/LinkedInIcon.svg'

export class SocialMedia extends React.Component {
    render() {
        return (
            <Grid centered container stackable style={{marginBottom: '1.25em'}}>
                {/*<Grid centered container stackable style={{marginTop:'-6.5em', marginBottom:'1.25em'}}>*/}
                <Grid.Row columns={2} verticalAlign='middle' centered style={{display: 'flex', flexWrap: 'nowrap'}}>
                    <Grid.Column textAlign="right" style={{fontSize: '1.5em', fontFamily: 'Montserrat'}}>Follow us
                        on: </Grid.Column>
                    <Grid.Column>
                        <Grid.Row style={{display: 'flex', flexGrow: 0}}>
                            <ImageComponent src={LinkedInIcon} size='3.125em'/>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}


// export const SocialMedia = () => {
//     return (
//         <Grid centered container stackable style={{marginTop:'-6.5em', marginBottom:'1.25em'}}>
//             <Grid.Row columns={2} verticalAlign='middle' centered style={{display:'flex', flexWrap:'nowrap'}}>
//                 <Grid.Column textAlign="right" style={{ fontSize: '1.5em',   fontFamily: 'Montserrat' }}>Follow us on: </Grid.Column>
//                 <Grid.Column>
//                     <Grid.Row style={{display:'flex', flexGrow:0 }} >
//                         <ImageComponent src = {LinkedInIcon} size = '3.125em' />
//                     </Grid.Row>
//                 </Grid.Column>
//             </Grid.Row>
//         </Grid>
//     )
// }

const ImageComponent = props => {
    const src = props.src
    const size = props.size
    return (
        <Grid.Column>
            <a href="https://www.linkedin.com/company/kyashifeood" target="_blank" rel="noopener noreferrer">
                <Image id={`${src}`}
                       alt={`${src}`}
                       src={src}
                       style={{
                           width: size,
                           height: size,
                       }}
                />
            </a>
        </Grid.Column>
    )
}